import { Typography } from "@mui/material";

import StyledLink from "shared/components/StyledLink";

const NeedHelpContactSupport = () => {
  return (
    <Typography variant="body2">
      Need help? Contact Support at{" "}
      <StyledLink href="tel:+18885640042">888-564-0042</StyledLink>
    </Typography>
  );
};

export default NeedHelpContactSupport;
