import { useState } from "react";

import assert from "assert";

import MagicBell, {
  FloatingNotificationInbox,
} from "@magicbell/magicbell-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Url } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";

import NeedHelpContactSupport from "shared/components/EmptyState/NeedHelpContactSupport";
import ServiceUpLogo from "shared/components/logos/ServiceUpLogo";
import ServiceUpMobileLogo from "shared/components/logos/ServiceUpMobileLogo";
import AskQuestion from "shared/components/modals/AskQuestion";
import NavbarLink from "shared/components/navbar/NavbarLink";
import { useAuth } from "shared/providers/AuthProvider";

import { FromWebsite } from "../../../../../backend/common/customerSupport.types";
import UserMenu from "../UserMenu";

type OptionProps = {
  text: string;
  icon: JSX.Element;
  href?: Url;
  onClick?: () => void;
};

const MagicBellIconButton = () => {
  const router = useRouter();
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (!currentUser?.email) return null;

  assert(
    process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY,
    "Magic Bell API key is missing"
  );

  return (
    <MagicBell
      apiKey={process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
      userEmail={currentUser.email}
      userExternalId={currentUser?.uid}
      locale="en"
      theme={{
        icon: {
          borderColor: "grey",
        },
        footer: {
          backgroundColor: "#FFFFFF",
          textColor: "#FFFFFF",
        },
      }}
    >
      {(props) => (
        <FloatingNotificationInbox
          width={isMobile ? 310 : 400}
          height={500}
          placement="top-start"
          hideArrow
          notificationPreferencesEnabled={false}
          onNotificationClick={(notification) => {
            router.push(`/requests/${notification.topic}`);
          }}
          {...props}
        />
      )}
    </MagicBell>
  );
};

const DrawerOption = ({ option }: { option: OptionProps }) => {
  const { href, onClick } = option;
  const router = useRouter();
  const buttonAction = href ? () => router.push(href) : onClick;

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={buttonAction}>
        <ListItemIcon>{option.icon}</ListItemIcon>
        <ListItemText primary={option.text} />
      </ListItemButton>
    </ListItem>
  );
};

const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const router = useRouter();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showAskQuestionModal, setShowAskQuestionModal] = useState(false);

  const primaryOptions = [
    { text: "Orders", icon: <SpaceDashboardOutlinedIcon />, href: "/" },
    { text: "History", icon: <FileCopyIcon />, href: "/history" },
    { text: "Staff", icon: <PersonIcon />, href: "/staff" },
    { text: "Help", icon: <HelpIcon />, href: "/help" },
  ];

  const secondaryOptions = [
    { text: "Profile", icon: <AccountCircleIcon />, href: "/profile" },
    {
      text: "Get Help",
      icon: <QuestionAnswerOutlinedIcon />,
      onClick: () => setShowAskQuestionModal(true),
    },
    {
      text: "Sign Out",
      icon: <LogoutIcon />,
      onClick: () => logout(),
    },
  ];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "background.default",
          zIndex: 999,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: {
                xs: "flex",
                lg: "none",
              },
              pl: 1.5,
            }}
          >
            <ServiceUpMobileLogo />
          </Box>

          <Box display={{ xs: "none", lg: "flex" }} gap={3} alignItems="center">
            <ServiceUpLogo />
            {currentUser && (
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <Stack spacing={5} direction={"row"}>
                  {primaryOptions.map((option) => (
                    <NavbarLink
                      currentPath={router.pathname}
                      href={option.href}
                      text={option.text}
                      key={option.text}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Box>

          {currentUser && (
            <Stack alignItems={"center"} flexDirection={"row"} columnGap={1}>
              <Box mt={1}>
                <MagicBellIconButton />
              </Box>
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <UserMenu />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                  },
                }}
              >
                <IconButton onClick={toggleDrawer(true)} size="large">
                  <MenuIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Box>
            </Stack>
          )}
          {!currentUser && <NeedHelpContactSupport />}
        </Toolbar>
      </AppBar>

      {currentUser && (
        <nav>
          <Drawer
            anchor={"right"}
            open={openDrawer}
            onClose={toggleDrawer(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            className={"box-mobile"}
            sx={{
              display: {
                xs: "block",
                lg: "none",
              },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 240,
              },
            }}
          >
            <Box
              sx={{ width: 250 }}
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {primaryOptions.map((option) => (
                  <DrawerOption option={option} key={option.text} />
                ))}
              </List>
              <Divider />
              <List>
                {secondaryOptions.map((option) => (
                  <DrawerOption option={option} key={option.text} />
                ))}
              </List>
            </Box>
          </Drawer>
        </nav>
      )}
      {showAskQuestionModal && (
        <AskQuestion
          onClose={() => setShowAskQuestionModal(false)}
          from={FromWebsite.ShopPortal}
        />
      )}
    </>
  );
};

export default Navbar;
